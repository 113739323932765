<template>
  <div>
    <validation-observer ref="formData">
      <div class="d-flex">
        <b-form class="formCovid w-100">
          <div class="text-center mb-3">
            <p class="mb-1"><strong></strong></p>
          </div>
          <div class="formCovid__title my-2">
            <h2 class="formCovid__title--container">
              Información Paciente Embarazada
            </h2>
          </div>
          <b-row class="mt-3">
            <template v-if="!formData.esMenor">
              <b-row>
              

                <b-col cols="12" md="4" class="mt-3">
                  <b-form-group
                    class="w-100"
                    label="Tipo de Documento (Cedula/Pasaporte)*"
                  >
                    <validation-provider
                      immediate
                      #default="{ errors }"
                      rules="required"
                      name="El tipo de documento"
                      vid="identificacionTipo"
                    >
                      <v-select
                        v-model="formData.typeDocumento"
                        :clearable="false"
                        label="value"
                        :options="listTypeIdentity"
                        :reduce="(option) => option.key"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4" class="mt-3">
                  <b-form-group
                    class="w-100"
                    label="Documento (Cedula/Pasaporte)*"
                  >
                    <validation-provider
                      immediate
                      #default="{ errors }"
                      :rules="`required|notSayPassport|${
                        formData.typeDocumento == 'C' ? 'document' : ''
                      }|documentMax:19`"
                      vid="typeDocumento"
                      name="El documento"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="formData.noDocumento"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Documento"
                        />
                       
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
                
                <b-col cols="12" md="4" class="mt-3">
                  <validation-provider
                immediate
                #default="{ errors }"
                rules="requiredIMG"
                vid="imgDocumento"
                name="El documento"
              >
            
              <b-form-group class="w-100" label="Imagen cedula/pasaporte *">
              
            <b-form-file
             v-model="formData.imgDocumento"
             :state="Boolean(formData.imgDocumento)"
             id="file-default"
            ></b-form-file>
          
                
             <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
                </b-col>


              </b-row>
            </template>
          </b-row>
          <b-row>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required"
                vid="nombre"
                name="Los nombres"
              >
                <b-form-group class="w-100" label="Nombres *">
                  <b-form-input
                    v-model="formData.nombre"
                    :disabled="false"
                    :state="errors.length > 0 ? false : null"
                    @input="formData.nombre = $event.toUpperCase()"
                    placeholder="nombres"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required"
                vid="apellido"
                name="Los apellidos"
              >
                <b-form-group class="w-100" label="Apellidos *">
                  <b-form-input
                    v-model="formData.apellido"
                    :disabled="false"
                    :state="errors.length > 0 ? false : null"
                    @input="formData.apellido = $event.toUpperCase()"
                    placeholder="Apellidos"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                :rules="`required|${formData.esMenor ? 'isMenor' : 'isAdult'}`"
                vid="fechaNacimiento"
                name="La fecha de nacimiento"
              >
                <b-form-group class="w-100" label="Fecha de Nacimiento *">
                  <flat-pickr
                    v-model="formData.fechaNacimiento"
                    :disabled="false"
                    :config="configBirthday"
                    class="form-control flat-picker bg-transparent"
                    placeholder="YYYY-MM-DD"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>

            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required"
                vid="celular"
                name="El celular"
              >
              <b-form-group class="w-100" label="Celular *">
                <b-form-input
                  :disabled="false"
                  v-model="formData.celular"
                  maxlength="10"
                  placeholder="Celular"
                />
                
                <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              
            </b-col>

            <b-col cols="12" md="4" class="mt-3">
              <b-form-group class="w-100" label="Telefono *">
                <b-form-input
                  :disabled="false"
                  v-model="formData.telefono"
                  placeholder="Telefono"
                  maxlength="10"
                />
              </b-form-group>
            </b-col>

         

            <b-col cols="12" md="4" class="mt-3">
          
                <b-form-group class="w-100" label="Correo Electrónico *">
                  <b-form-input
                    :disabled="false"
                    v-model="formData.email"
                  
                    placeholder="Correo electrónico"
                  />
                
                </b-form-group>
             
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="4" class="mt-3">
              <b-form-group class="w-100" label="Direccion *">
                <b-form-input
                  :disabled="false"
                  v-model="formData.direccion "
                  @input="formData.direccion = $event.toUpperCase()"
                  placeholder="Direccion"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" class="mt-3">
              <b-form-group class="w-100" label="Ciudad *">
                <b-form-input
                  :disabled="false"
                  v-model="formData.ciudad"
                  @input="formData.ciudad = $event.toUpperCase()"
                  placeholder="Ciudad"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" class="mt-3">
              <b-form-group class="w-100" label="Provincia *">
                <b-form-input
                  :disabled="false"
                  v-model="formData.provincia"
                  @input="formData.provincia = $event.toUpperCase()"
                  placeholder="Provincia"
                />
              </b-form-group>
            </b-col>

        

          </b-row>

          <b-row>

            <b-col cols="12" md="4" class="mt-3">
              <b-form-group class="w-100" label="Sector *">
                <b-form-input
                  :disabled="false"
                  v-model="formData.sector"
                  @input="formData.sector = $event.toUpperCase()"
                  placeholder="Sector"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required"
                vid="fechaGestacion"
                name="La fecha de gestacion"
              >
                <b-form-group class="w-100" label="Fecha de Gestacion *">
                  <flat-pickr
                    v-model="formData.fechaGestacion"
                    :disabled="false"
                    :config="configBirthday"
                    class="form-control flat-picker bg-transparent"
                    placeholder="YYYY-MM-DD"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4" class="mt-3">
                  <validation-provider
                immediate
                #default="{ errors }"
                rules="requiredIMG"
                vid="imgResul"
                name="El resultado"
              >
            
              <b-form-group class="w-100" label="Imagen Resultado Positivo (Embarazo) *">
              
            <b-form-file
             v-model="formData.imgResul"
             :state="Boolean(formData.imgResul)"
             id="file-default"
            ></b-form-file>
          
                
             <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
                </b-col>

          </b-row>

          <br />
          <!--codigo datos ARS -->
          <div>

            <div class="formCovid__title my-2">
              <h2 class="formCovid__title--container">
                Datos Paciente Asegurado
              </h2>
            </div>

          <b-row>

            <b-col cols="12" md="4" class="mt-3">
          
                <b-form-group class="w-100" label="Aseguradora *">
                  <b-form-input
                    v-model="formData.nombreAseguradora"
                    :disabled="false"
                    placeholder="Nombre Aseguradora"
                    @input="formData.nombreAseguradora = $event.toUpperCase()"
                  />
                 
                </b-form-group>
          
            </b-col>

            <b-col cols="12" md="4" class="mt-3">
         
                <b-form-group class="w-100" label="No. Afiliado *">
                  <b-form-input
                    v-model="formData.noAfiliado"
                    :disabled="false"
                    placeholder="No. Afiliado"
                  />
               
                </b-form-group>
         
            </b-col>
            
            <b-col cols="12" md="4" class="mt-3">
          
          <b-form-group class="w-100" label="Imagen del Carnet:" label-cols-sm="2" label-size="sm">
          <b-form-file
           v-model="formData.imgCarnet"
           :state="Boolean(formData.imgCarnet)"
           id="file-default"
          ></b-form-file>
     
          </b-form-group>
          
          </b-col>

          </b-row>
       

      
          </div>

          <!--Find codigo ARS -->
       
          <div  class="d-flex justify-content-center ">
            <b-col cols="12" md="4" class="mt-3">
              <b-button
                @click="postDatos()"
                class="w-100 py-2"
                variant="primary"
              >
                <strong style="font-size: 1.05rem"> Confirmar</strong>
              </b-button>
            </b-col>
          </div>
        </b-form>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BInputGroup,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import validateIdentityCard from "@/utils/ValidateIdentity";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { required, email, numeric, max } from "vee-validate/dist/rules";
import {
  LST_SEXOS,
  LST_TIPO_IDENTIFICACION,
  LST_TIPO_FAMILIA,
  LST_NACIONALIDADES,
  LST_PARENTESCOS_MENORES,
} from "@/utils/constants.js";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

extend("required", {
  ...required,
  message: (field) => `${field} es requerido `,
});

extend("requiredIMG", {
  ...required,
  message: (field) => `${field} es requerido. Formato permitido: jpg., png. y jpeg.  `,
});


extend("numeric", {
  ...numeric,
  message: (filed) => `${filed} solo debe de contener números`,
});

extend("email", {
  ...email,
  message: (filed) => `${filed} incresado no es valido`,
});
extend("documentMax", {
  ...max,
  message: (filed) => `${filed} máximo de caracteres 19`,
});

extend("document", {
  validate(value) {
    return validateIdentityCard(value);
  },
  message: "Cédula incorrecta",
});

extend("notSayPassport", {
  validate(value) {
    let isValid = [
      "PASAPORTE",
      "PASSPORT",
      "PASA",
      "PORTE",
      "SAPORTE",
      "PASS",
    ].includes(value.toUpperCase());
    return !isValid;
  },
  message: "El documento es inválido",
});

extend("isAdult", {
  validate(value) {
    const today = new Date().toISOString().split("T")[0].replace(/-/g, "");
    const birthdate = new Date(value)
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");
    const age = Math.floor((today - birthdate) / 10000, 0);
    return age >= 18;
  },
  message: "Debe ser mayor o igual a 18 años",
});

extend("isMenor", {
  validate(value) {
    const today = new Date().toISOString().split("T")[0].replace(/-/g, "");
    const birthdate = new Date(value)
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");
    const age = Math.floor((today - birthdate) / 10000, 0);

    return age < 18;
  },
  message: "Debe ser menor de 18 años",
});

export default {
  props: {
    dataOrder: {
      type: Object,
      default: null,
    },
    clean: {
      type: Date,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    vSelect,
    BRow,
    BCol,
    flatPickr,
    BButton,
    BInputGroup,
    BFormFile,
   
  },
  data() {
    return {
      listSex: LST_SEXOS,
      listTypeIdentity: LST_TIPO_IDENTIFICACION,
      listTypeFam: LST_TIPO_FAMILIA,
      listNationality: LST_NACIONALIDADES,
      listRelantionship: LST_PARENTESCOS_MENORES,
      loadingButtom: false,

      formData: {
        nombre:"",
        apellido:"",
        typeDocumento: null,
        noDocumento: "",
        imgDocumento:null,
        fechaNacimiento: "",
        telefono:"",
        celular: "",
        email: "",
        direccion: "",
        ciudad:"",
        provincia: "",
        sector:"",
        codigoPostal: 11005,
        nombreAseguradora: "",
        noAfiliado:"",
        imgCarnet:null,
        fechaGestacion:"",
        imgResul:null



      },

     
      configBirthday: {
        maxDate: new Date(),
        minDate: new Date(
          new Date() - 1000 * 60 * 60 * 24 * 51100
        ).toLocaleDateString(),
        locale: Spanish,
        disableMobile: true,
      },
    };
  },

  methods: {
    getEmployee() {
      this.$loading.show();
      this.$store
        .dispatch("getEmployee", {
          code: this.formData.codigoEmpleado,
          cedula: this.formData.identificacionNumero,
        })
        .then((resp) => {
          this.formData.nombres = resp.firstName + " " + resp.secondName;
          this.formData.apellidos = resp.lastName + " " + resp.lastName2;
          this.formData.fechaNacimiento = resp.birthDate;
          this.formData.nacionalidad = resp.nationality;
          this.formData.sexo = resp.gender;
          this.formData.email = resp.email;
          this.formData.codigoEmpleado = resp.employee;
          this.dependienteData[0].employee = resp.employee;
          this.dependienteData[0].user = resp.employee;
          this.$loading.hide();
          this.$swal.fire("Éxito", "Colaborador encontrado", "success");
          console.log(resp);
        })
        .catch((e) => {
          this.$loading.hide();
          this.$swal.fire("Error", e.message, "error");
        });
    },

    async postDatos() {
      const isValid = await this.$refs.formData.validate();
      if (isValid) {
        console.log(
          this.formData.noDocumento,
          this.formData.nombre,
          this.formData
        );

        const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    };
    if (this.formData.imgCarnet) {
      this.formData.imgCarnet = await convertFileToBase64(this.formData.imgCarnet);
      var base64imgCarnet= this.formData.imgCarnet.replace(/^data:image\/(png|jpeg);base64,/, '');
      this.formData.imgCarnet=base64imgCarnet;
    }

    if (this.formData.imgDocumento) {
      this.formData.imgDocumento = await convertFileToBase64(this.formData.imgDocumento);
      var base64imgDocumento= this.formData.imgDocumento.replace(/^data:image\/(png|jpeg);base64,/, '');
      this.formData.imgDocumento=base64imgDocumento;
    }

    if (this.formData.imgResul) {
      this.formData.imgResul = await convertFileToBase64(this.formData.imgResul);
      var base64imgResul= this.formData.imgResul.replace(/^data:image\/(png|jpeg);base64,/, '');
      this.formData.imgResul=base64imgResul;
    }

    if(this.formData.noDocumento){
      var cedulaSinGuion = this.formData.noDocumento.replace(/-/g,'');
      this.formData.noDocumento =cedulaSinGuion; 
    }
        var data = this.formData;
 
      console.log(data)

        this.$loading.show();
        this.$store
          .dispatch("postEmbarazada", data)
          .then((resp) => {
            this.$loading.hide();
            this.$swal.fire("Éxito", "Embarazada registrada", "success");

         // Limpiar el formulario
        this.formData = {
          nombre: "",
          apellido: "",
          typeDocumento: null,
          noDocumento: "",
          imgDocumento: null,
          fechaNacimiento: "",
          telefono: "",
          celular: "",
          email: "",
          direccion: "",
          ciudad:"",
          provincia: "",
          sector: "",
          nombreAseguradora: "",
          noAfiliado: "",
          imgCarnet: null,
          fechaGestacion:"",
          imgResul:null
        };

            this.solicitud = resp.response;
          })
          .catch((e) => {
          
            this.$loading.hide();
            this.$swal.fire("Error", e.message +" "+"Registro ya existe en el sistema", "error");
          });
      } else {
        this.$swal.fire(
          "Error",
          "Verificar los campos que son obligatorio",
          "error"
        );
      }
    },



    add(clave) {
      if (clave == "inputDependientes")
        this.dependienteData.push({
          employee: this.formData.codigoEmpleado,
          famType: "",
          firstName: "",
          secondName: "",
          lastName: "",
          lastName2: "",
          birthday: "",
          gender: "",
          user: this.formData.codigoEmpleado,
        });
    },

    remove(clave, index) {
      if (clave == "inputDependientes") {
        this.dependienteData.splice(index, 1);
      }
    },
    async sendData() {
      const isValid = await this.$refs.formCovid.validate();

      const isValidCredicar = !Object.values(this.dataCreditCard).some(
        (x) => x == ""
      );
      if (!isValid) {
        let listErrors = Object.values(this.$refs.formCovid.errors).filter(
          (x) => {
            return x.length > 0;
          }
        );

        let ulErrors = [];
        ulErrors.push(`<ul style="text-align:left">`);
        listErrors.forEach((el) => {
          ulErrors.push(`<li class="text-danger">${el[0]}</li>`);
        });
        ulErrors.push("</ul>");

        this.$swal({
          title: "Por favor verificar ",
          html: `${ulErrors.join("")}`,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      if (this.amountTest == 0) {
        this.$swal({
          title: "Error",
          text: "Debe de seleccionar al menos una prueba ",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      if (!isValidCredicar) {
        this.$swal({
          title: "Error",
          text: "Por favor verificar que todos los campos correspondiente su tarjeta estén completos",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return;
      }

      if (!this.formData.esMenor) {
        this.setDataSolicitante();
      }
      this.formData.pagoInfo = this.getEncryptDataCredit(this.dataCreditCard);

      this.$emit("handleData", {
        dataForm: {
          ...this.formData,
          monto: this.amountTest,
        },
        dataCard: { ...this.dataCreditCard },
      });
    },
    getEncryptDataCredit(data) {
      var text = `${data.cardName}|${data.cardNumber}|${data.cardMonth}/${data.cardYear}|${data.cardCvv}`;
      const base64 = btoa(text);
      return base64;
    },
    handleSelect(value) {
      this.formData.sector = value;
    },
    autocompleteUser($event) {
      if ($event.target.value.trim() == "") {
        this.formData.direccionReferencia = "";
      }
    },
    getDataCreditCard(data) {
      this.dataCreditCard = data;
    },
    setDataSolicitante() {
      this.formData.solicitanteIdentificacionNumero =
        this.formData.identificacionNumero;
      this.formData.solicitanteIdentificacionTipo =
        this.formData.identificacionTipo;
      this.formData.solicitanteNombres = this.formData.nombres;
      this.formData.solicitanteApellidos = this.formData.apellidos;
      this.formData.solicitanteTelefono = this.formData.telefonoMovil;
      this.formData.solicitanteEmail = this.formData.email;
      this.formData.solicitanteSexo = this.formData.sexo;
    },
  },
};
</script>

<style lang="scss">
.rbtn-group {
  border-radius: 0px 5px 5px 0px;
}
.formCovid {
  &__title {
    text-align: center;
    background: #000;
    padding: 7px;
    &--container {
      font-size: 1rem;
      margin: 0;
      color: #fff;
    }
  }

  > div:first-child {
    color: #6e6b7b;
  }
  .radio--age {
    font-size: 1.2rem;
  }
}
</style>
