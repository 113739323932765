<template>
  <div>
    <header class="text-center">
      <img
        class="mt-2"
        style="width: 200px"
        :src="require('@/assets/images/logos/Materna-Logo.png')"
        alt="Logo"
      />
    <br>
    <br>
      
        <h1 class="my-2 containerForm--title">Formulario Registro de Embarazada</h1>
        <h2 class="my-2 containerForm--title">Referencia Materna</h2>


      <p class="d-inline infoBranch mb-8">
        {{ moment().locale("es").format("DD") }}
        de
        {{ moment().locale("es").format("MMMM  YYYY, h:mm:ss a") }}
      </p>
      <slot >
        <p>
          Complete el formulario y haga clic en el botón
          <strong>Confirmar</strong>
        </p>
      </slot>
    </header>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    payload: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      moment: moment,
    };
  },
};
</script>
